import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { API_MAIN_URL } from '../constants';

@Component({
  selector: 'app-audio-dialog',
  templateUrl: './audio-dialog.component.html',
  styleUrls: ['./audio-dialog.component.scss']
})
export class AudioDialogComponent implements OnInit {
 	
 	//Lets initiate Record OBJ
  private record;
  //Will use this flag for detect recording
  private recording = false;
  //Url of Blob
  private url;
  private error;
  private audioTimer="00:00";
  private mins;
  private sgs;
  private refreshIntervalId;
  private refreshTimeoutId;
  private blob;
  private domainUrl: string = API_MAIN_URL;


  constructor(public dialogRef: MatDialogRef<AudioDialogComponent>,private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.setUrl();
  }

  onClose() {
    this.dialogRef.close();
  }

  sanitize(url:string){
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  setUrl():void {
    this.domainUrl = window.location.host === 'localhost:4200' ? '' : this.domainUrl;
  }

  audioTimerFunction(){
  	this.sgs++;
  	if(this.sgs==60){
  		this.sgs=0;
  		this.mins++;
  	}
  	this.audioTimer=((this.mins<10)?"0"+this.mins:this.mins) + ":" + ((this.sgs<10)?"0"+this.sgs:this.sgs);
  }

  clearAudioTimer(){
    this.audioTimer="00:00";
  	this.mins=0;
  	this.sgs=0;

  }

  initiateAudioTimer() {
  	this.clearAudioTimer();
    this.refreshIntervalId=setInterval(() => {this.audioTimerFunction()},1000);
  }

  /**
   * Start recording.
   */
  
  initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices
    .getUserMedia(mediaConstraints)
    .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  /**
   * Will be called automatically.
   */

  successCallback(stream) {
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1
    }; 
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
    this.refreshTimeoutId=setTimeout(() => {this.stopRecording()},120000);
    this.initiateAudioTimer();
  }

  /**
   * Stop recording.
   */

  stopRecording() {
		clearInterval(this.refreshIntervalId);
		clearTimeout(this.refreshTimeoutId);
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  	this.clearAudioTimer();
  }

  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  
  processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    this.blob=blob;
  }

  /**
   * Process Error.
   */

  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }

  deleteAudio(){
  	this.clearAudioTimer();
  	this.url=null;
  }

  onSave() {
    this.dialogRef.close(this.blob);
  }
}
