import * as actionTypes from '../actions/home.actions';
import {HomeState} from '../models/home.model';
import {BannerService} from '../models/BannerService.model';
import { NavOption } from '../interfaces/navOption.interface';
import { MainService } from '../interfaces/mainService.interface';
import { SecondaryService } from '../interfaces/secondaryService.interface';
import * as CONSTANTS from 'src/app/contants/app.contants';


const initialState: HomeState = {
  openLoginForm: false,
  navOptions: <NavOption[]>[
    {
      title: 'SERVI2',
      route: ''
    },
    {
      title:'Productos',
      route: '/'
    },
    {
      title:'Servicios',
      route: '/'
    },
    {
      title:'Paga aqui',
      route: '/'
    }
  ],
  bannerServices: [
    new BannerService('home-services.png', 'asistencia hogar 24/7'),
    new BannerService('gas-services.png', 'asistencia gas'),
    new BannerService('telemedicina-services.png', 'asistencia telemedicina')
  ],
  acceptedCookies: false,
  mainServices: <MainService[]>[
    {
      img: 'assets/images/main-service-servi2.png',
      title: 'SERVI2',
      description: 'Programa de asistencia diseñado por Engie que protege a tu familia, hogar y cuida tu economía.',
      route: CONSTANTS.SOLICITUD_URL
    },
    {
      img: 'assets/images/main-service-club-servi2.png',
      title: 'Club SERVI2',
      description: 'Conoce los beneficios que tenemos para nuestros clientes, como descuentos en cine, restaurantes, etc.',
      route: ""
    },
    {
      img: 'assets/images/main-service-services.png',
      title: 'Servicios',
      description: 'Contamos con un grupo de expertos que acudirán a tu hogar a realizar la inspección de tus productos.',
      route: ""
    }
  ],
  secondaryServices: <SecondaryService[]>[
    {
      img: 'assets/images/secondary-service-boiler-ideal.png',
      title: 'Boiler ideal',
      description: 'Descubre cuál es el calentador que más se acomoda a sus necesidades.'
    },
    {
      img: 'assets/images/secondary-service-tienda.png',
      title: 'Tienda',
      description: 'Aquí encontrarás los mejores productos para el ahorro de energía en el hogar.'
    },
    {
      img: 'assets/images/secondary-service-mi-cuenta-engie.png',
      title: 'Mi cuenta ENGIE',
      description: 'Aquí encontrarás los mejores productos para el ahorro de energía en el hogar.'
    }
  ]

};

export function homeReducer ( state = initialState, action: actionTypes.actions) {
  let newState = Object.assign({}, state);
  switch(action.type){
    case actionTypes.OPEN_LOGIN_VIEW:
      newState.openLoginForm = true;
      return newState;
    case actionTypes.CLOSE_LOGIN_VIEW:
      newState.openLoginForm = false;
      return newState;

    case actionTypes.SET_ACCEPTED_COOKIES:
      newState.acceptedCookies = true;
      return newState;

    default:
      return newState;
  }
}
