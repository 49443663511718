import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {PrivacyComponent} from "./components/privacy/privacy.component";
import {TermsComponent} from "./components/terms/terms.component";
import {AyudaComponent} from "./components/ayuda/ayuda.component";
import {AuthGuard} from "./core/api/client/auth.guard";
import {ClientenoengieComponent} from "./components/clientenoengie/clientenoengie.component";
import { HomeNoEngieComponent } from './components/home/home-no-engie/home-no-engie.component';
import { Servi2AccountsComponent } from './components/servi2-accounts/servi2-accounts.component';
import { ValidateEmailComponent } from './components/validateemail/validate-email.component';
import { LayOnComponent } from './components/shared/lay-on/lay-on.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';


const ROUTES: Routes = [
  {path: 'terms', component: TermsComponent},
  {path: 'home', component: HomeComponent},
  {path: 'privacidad', component: PrivacyComponent},
  {path: 'condiciones', component: TermsComponent},
  {path: 'ayuda', component: AyudaComponent},
  {path: 'homenoengie', component: HomeNoEngieComponent},
  {path: 'validate_email', component: ValidateEmailComponent},
  {path: 'activar-cuenta', component: LayOnComponent},
  {path: 'cambiar-contrasena', component: ChangePasswordFormComponent},
  {path: 'clientnotengie', component: ClientenoengieComponent},
  {path: 'forgetpass', component: ForgotPasswordFormComponent},
  {path: 'cuentas', component: Servi2AccountsComponent},
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: '**', pathMatch: 'full', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class AppRoutingModule { }
