import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as homeActionTypes from 'src/app/actions/home.actions';

@Component({
  selector: 'banner-cookies',
  templateUrl: './banner-cookies.component.html',
  styleUrls: ['./banner-cookies.component.scss']
})
export class BannerCookiesComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
  }

  setHideCookies(): void {
    localStorage.setItem('acceptedCookies', "true");
    this.store.dispatch(new homeActionTypes.AcceptedCookiesActions());
  }


}
