import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, Observer, Subject} from "rxjs";
import { AssistanceObserver, TutenClientReasonCancellation, TutenBookingChatMessage } from './booking.interfaces';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  //AssitanceStatusObserver
  private assistanceSubject: Subject<AssistanceObserver> = new Subject<AssistanceObserver>();

  constructor(
    public api: ApiService,
    public http: HttpClient
  ) { }

  getAssistanceSubjectAsObserver(): Observable<AssistanceObserver> {
    return this.assistanceSubject.asObservable();
  }

  emitAssitance(assistanceObserver: AssistanceObserver): void {
    this.assistanceSubject.next(assistanceObserver);
  }

  getServicesDefinition() {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('booking/definitions', null, null, headers);
  }

  getServicesGroups() {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('booking/definitions/groups', null, null, headers);
  }

  getServicesGroupsNoId() {
    return this.api.get('booking/definitions/groupsnoid', null, null, null);
  }

  getImageBlob(url: string): any {
    const headers = {};
    return this.http.get(url, { responseType: 'blob', });
  }

  uploadBookingAudio(audio, bookingId) {
    const formData: FormData = new FormData();
    formData.append('file', audio, audio.name);
    const params = {
      label: 'Evidencia de cliente'
    };
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post('booking/' + bookingId + '/audios', formData, headers, params);
  }

  uploadBookingImage(image, bookingId) {
    const formData: FormData = new FormData();
    formData.append('file', image, image.name);
    const params = {
      label: 'Evidencia de cliente'
    };
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post('booking/' + bookingId + '/images', formData, headers, params);
  }

  uploadBookingDocument(document, bookingId) {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    const params = {
      label: 'Evidencia de cliente'
    };
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post('booking/' + bookingId + '/documents', formData, headers, params);
  }

  uploadBookingVideo(video, bookingId) {
    const formData: FormData = new FormData();
    formData.append('file', video, video.name);
    const params = {
      label: 'Evidencia de cliente'
    };
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post('booking/' + bookingId + '/videos', formData, headers, params);
  }

  getBookingFiles(bookingId) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('booking/' + bookingId + '/files', null, null, headers);
  }

  removeBookingFile(id) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.delete('booking/files/' + id, null, headers);
  }

  getBooking(bookingId) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('booking/' + bookingId, null, null, headers);
  }

  getBudgetForApprove(budgetId) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('/booking/'+ budgetId +'/budget', null, null, headers);
  }

  searchBookings(data) {
    const body = data.body;
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    const params = {
      page: data.page,
      pagesize: data.pagesize
    };
    return this.api.post('booking/search', body, headers, params);
  }


   getTotalcounts() {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.get('booking/counts', null,null, headers);
  }

  /*
    Consulta los profesionales del contrato asignado
  */
  getBookingContractorsAssign()
  {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };

    return this.api.get('/booking/servi2/contractor/professionals', null, null, headers);
  }

  /**
   * Obtiene Solicitudes de asistencia con encuesta pendiente de responder
   */
  getBookingsIncompletePoll() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };

    return this.api.get('client/booking/poll/incomplete', null, null, headers);
  }

  /**
   * Obtiene Solicitudes con presupuesto por pagar
   */
  getBookingsBudgetForPay() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };

    return this.api.get('client/booking/budget/notpay', null, null, headers);
  }

    /**
   * Obtiene Solicitudes con presupuesto por pagar
   */
  getBookingsBudgetForApprove(data) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    return this.api.post('booking/for-approve', data.filters , headers, data.pagination);
  }

  /**
  * Aprobar presupuesto de una asistencia
  */
  approveBudget(id: any){
    const headers = { 
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.put(`booking/${id}/budget/approve`,null, headers);
  }

  /*
     Consulta los profesionales que están habilitados en la fecha seleccionada por el usuario
  */
  searchBookingProfessionalBusyRanges(data: any)
  {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    let body = {
      start:data.start,
      end:data.end,
      professionals:data.professionals
    };

    return this.api.post('booking/' + data.bookingId + '/professional/busy/ranges', body, headers, null);
  }

  rescheduleBookingToProfessional(data: any)
  {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    let body = {
      start: data.body.start,
      end: data.body.end,
      desiredDate: data.body.desiredDate
    };
    let params = {
      timezoneMin: data.timezoneMin,
      reasonReschedule: data.reasonReschedule
    };
    if(data.transferId){
      params['transferId']=data.transferId;
    }

    return this.api.post('booking/' + data.bookingId + '/reschedule/' + data.professionalId, body, headers, params);
  }

  /*
    Consulta los profesionales del contrato asignado
  */
  getBookingMatch(data: any)
  {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };

    let params = {
      fechaInicio:data.start,
      fechaFin:data.end//,
      //fechaInicioServicio:data.fechaInicio,
      //fechaFinServicio:data.fechaFin
    };

    return this.api.get('booking/' + data.bookingId + '/match',params,null, headers);
  }

  getUncompletedSurveys() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    return this.api.get(`booking/polls/${ localStorage.getItem('clientId') }/incompleted`, null, null, headers);
  }

  getPollByBookingId(bookingId: any): Observable<any> {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    return this.api.get(`booking/${ bookingId }/poll`, null, null, headers);
  }

  submitPollResponse(bookingId: number, answeredPoll: any): Observable<any> {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    return this.api.put(`booking/${ bookingId }/poll/response`, answeredPoll, headers);
  }
  getHelpRequets(bookingId) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('client/booking/' + bookingId + '/help/requests', null, null, headers);
  }

  getStates(stateName) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    const params = {
      name: stateName.toString()
    };
    return this.api.get('booking/states', params, null, headers);
  }

  getTimeZone(lat, lng) {
    return this.api.timezone(lat, lng);
  }

  /**
   * Servicio para cancelar los servicios hechos por el cliente
   * @param id ID del Booking
   * @param clientReasonCancellation Objeto que especifica la razon de cancelacion
   */
  cancelBooking(id: number, clientReasonCancellation: TutenClientReasonCancellation): Observable<any> {
    const headers = { 
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };

    const body = { ...clientReasonCancellation }

    return this.api.put(`booking/${id}/cancellation`, body , headers);
  }

  /**
   * Servicio para rechazar presupuesto de una asistencia
   * @param id ID del Booking
   * @param data Razón por la cual el cliente rechaza el presupuesto de la asistencia
   */
  rejectBudget(id: any, data: any): Observable<any>{
    const headers = { 
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post(`booking/${id}/budget/reject`, data, headers, null);
  }

  getStateTransfersByStateId(stateId) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    const params = {      
      stateId: parseInt(stateId)
    };
    return this.api.get('booking/transfers/state', params, null, headers);
  }

  getChatByBookingId(bookingId: number) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.get(`booking/servi2/booking/${ bookingId }/chat/messages`, null, null, headers);
  }

  /**
   * https://devservi2.tuten.cl/Servi2ClientsREST/#!/booking/putBookingChat
   * @param bookingId id of the booking
   */
  putBookingChat(bookingId: number, message: TutenBookingChatMessage) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.put(`booking/servi2/booking/${ bookingId }/chat`, message, headers);
  }
}

