import { Component, OnInit, OnDestroy } from '@angular/core';
import {BookingsService} from "../../core/api/bookings/bookings.service";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as homeActionTypes from 'src/app/actions/home.actions';
import { MainService } from 'src/app/interfaces/mainService.interface';
import { SecondaryService } from 'src/app/interfaces/secondaryService.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  openLoginForm:boolean;

  acceptedCookies: boolean;

  groups: any[];

  componentSubscr: Subscription = new Subscription();

  mainServices: MainService[];

  secondaryServices: SecondaryService[];

  constructor(public booking: BookingsService, private store: Store<AppState>) {
    this.store.select('home').subscribe(
      home => {
        this.openLoginForm = home.openLoginForm;
        this.acceptedCookies = home.acceptedCookies;
        this.mainServices = home.mainServices;
        this.secondaryServices = home.secondaryServices;
      }
    )

    this.setAcceptedCookies();
  }

  setAcceptedCookies(): void {
    if(localStorage.getItem('acceptedCookies') != null && localStorage.getItem('acceptedCookies') === "true") {
      this.store.dispatch(new homeActionTypes.AcceptedCookiesActions());
    }
  }

  ngOnInit() {
    this.getServicesGroupsNoId();
  }

  ngOnDestroy() {
    this.componentSubscr.unsubscribe();
  }

  public getServicesGroupsNoId() {
    const getServicesSubsc = this.booking.getServicesGroupsNoId().subscribe((response: any) => {
      if (response != undefined) {
        this.groups = response;
      }
    });
    this.componentSubscr.add(getServicesSubsc);
  }

  navigate(url: string): void {
    if(url !== null && url !== ""){
      window.open(url, '_blank');
    }
  }
}
