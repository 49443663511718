import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClientService } from './core/api';
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from "@angular/router";
import { API_MAIN_URL } from "./components/shared/constants";
import { Subscription } from 'rxjs';
import { AssistanceObserver } from './core/api/bookings/booking.interfaces';
import { BookingsService } from './core/api/bookings/bookings.service';
import { MatDialog } from '@angular/material';
import { WebsocketService } from './websocket.service';
import { Store } from '@ngrx/store';
import { AppState } from './interfaces/app.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent implements OnInit, OnDestroy {
  nombre;
  telefono;
  motivo;
  url: string = API_MAIN_URL;
  href: string = "";
  dashboard: boolean;
  isLogingView: boolean;
  componentSubscriptions: Subscription = new Subscription();
  viewCallback: boolean;
  name_user: String;
  loading: boolean;
  isLogged: boolean;


  constructor(
    private clientService: ClientService,
    private bookingService: BookingsService,
    private router: Router,
    public dialog: MatDialog,
    private _wsService: WebsocketService,
    private store: Store<AppState>
  ) {
    this._wsService.mensajeAprobacion()
      .subscribe((data:any)=> {
        // console.log("---- Mensaje privado ---");
        this.viewCallback = true;
        this.name_user = data.usuario;
      });

      this.store.select('app').subscribe(
        app => {
          this.loading = app.loading;
          this.isLogged = app.isLogged;
        }
      )
   };


  ngOnInit() {
    this.joinWebSocket();
    this.implementsLoggedUserLogic();
    /* Temporal */
    // localStorage.setItem('email', 'usuario@tuten.cl');
    // localStorage.setItem('clientId', '1');
    // localStorage.setItem('sessionToken', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');
    // this.loginClient();
    // this.validateTokenClient();
    /* Temporal */
  }

  ngOnDestroy() {
    this.componentSubscriptions.unsubscribe();
  }

  onCloseDialogCallback(){
    this.viewCallback = false;
  }

  joinWebSocket(){
    //Realizar conexión con el Sockets y un topico
    let  user = "Cliente_" + Math.floor((Math.random() * 100000) + 1);
    let topic = "topicGeneral";
    this._wsService.joinConnection({nombre: user, topic:topic, type: 'client_solicitud_digital'})
    .subscribe((...data: any[])=>{
        // console.log("Usuario conectado WebSocket al topic " + topic);
    });
  }

  implementsLoggedUserLogic(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.href = this.router.url;
        let respHref = (this.href.split('/'));
        const excludedViews = ['home'];
        const excludedViewsNavbar = ['validate_email', 'login']
        this.isLogingView = excludedViewsNavbar.includes(respHref[1].split('?')[0]);
        this.dashboard = excludedViews.includes(respHref[1]);
      }
    });
  }


  public validateTokenClient() {
    this.clientService.validateTokenClient().subscribe((data: any) => {
      // console.log(data);
    });
  }
}

