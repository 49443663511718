import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpUrlEncodingCodec  } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule, NgProgressInterceptor } from 'ngx-progressbar';
import { MAT_DATE_LOCALE } from "@angular/material";
import {CarouselModule} from "ngx-owl-carousel-o";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ScrollingModule} from "@angular/cdk/scrolling";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';

// Services
import { AuthGuard } from "./core/api/client/auth.guard";
import { SnackbarService } from './core/api/snackbar.service';
import { ProgressBarService } from './core/api/progress-bar.service';
import { WebsocketService } from './websocket.service';
import { MessageService } from 'primeng/api';

//Pipes
import { isRequiredPipe } from './core/pipes/isRequired.pipe';
import { PhonePipe } from './core/pipes/phone.pipe';
import { RangePipe } from './core/pipes/range.pipe';

//Directives
import OnlyNumbers from './core/directives/only-numbers';
import { ResponsiveColumnsDirective } from "./core/directives/responsive-columns.directive";

// ngrx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment.prod';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { LogoutDialogComponent } from './components/shared/logout-dialog/logout-dialog.component';
import { AudioDialogComponent } from './components/shared/audio-dialog/audio-dialog.component';
import { DocumentDialogComponent } from './components/shared/document-dialog/document-dialog.component';
import { VideoDialogComponent } from './components/shared/video-dialog/video-dialog.component';
import { ImageDialogComponent } from './components/shared/image-dialog/image-dialog.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { ProgresbarComponent } from './components/shared/progresbar/progresbar.component';
import { SnackbarComponent } from './components/shared/snackbar/snackbar.component';
import { ReminderDialogComponent } from './components/shared/reminder-dialog/reminder-dialog.component';
import { AyudaComponent } from './components/ayuda/ayuda.component';
import { HomeNoEngieComponent } from './components/home/home-no-engie/home-no-engie.component';
import { DialogRegisterComponent } from './components/shared/dialog-register/dialog-register.component';
import { ClientenoengieComponent } from './components/clientenoengie/clientenoengie.component';
import { Servi2AccountsComponent } from './components/servi2-accounts/servi2-accounts.component';
import { CellphoneRegisterComponent } from './components/shared/cellphone-register/cellphone-register.component';
import { RegisterComponent } from './components/login/register-component/register-component.component';
import { ValidateEmailComponent } from './components/validateemail/validate-email.component';
import { EngieDetailsComponent } from './components/login/register-component/engie-details/engie-details.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MaterialModule} from './material/material.module';
import { HttpErrorInterceptor } from './core/global/http-error.nterceptor';
import { LayOnComponent } from './components/shared/lay-on/lay-on.component';
import { CustomMatPanelComponent } from './components/shared/custom-mat-panel/custom-mat-panel.component';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderAppComponent } from './components/header-app/header-app.component';
import { HeaderMenuMobileComponent } from './components/header-menu-mobile/header-menu-mobile.component';
import { SidebarModule } from 'primeng/sidebar';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { CheckboxModule } from 'primeng/checkbox';
import { BannerHomeDetailsComponent } from './components/home/banner-home-details/banner-home-details.component';
import { CardModule } from 'primeng/card';
import { LoginFormMobileComponent } from './components/login-form-mobile/login-form-mobile.component';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { BannerForgotDetailsComponent } from './components/forgot-password-form/banner-forgot-details/banner-forgot-details.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';

// reducers
import { homeReducer } from './reducers/home.reducer';
import { appReducer } from './reducers/app.reducer';
import { BannerCookiesComponent } from './components/home/banner-cookies/banner-cookies.component';

@NgModule({
  entryComponents:[
    LogoutDialogComponent,
    AudioDialogComponent,
    DocumentDialogComponent,
    VideoDialogComponent,
    ImageDialogComponent,
    SnackbarComponent,
    ReminderDialogComponent,
    DialogRegisterComponent,
    CellphoneRegisterComponent
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    PrivacyComponent,
    TermsComponent,
    LogoutDialogComponent,
    AudioDialogComponent,
    DocumentDialogComponent,
    VideoDialogComponent,
    ImageDialogComponent,
    LoadingComponent,
    ProgresbarComponent,
    SnackbarComponent,
    ReminderDialogComponent,
    isRequiredPipe,
    DialogRegisterComponent,
    ClientenoengieComponent,
    AyudaComponent,
    OnlyNumbers,
    ResponsiveColumnsDirective,
    HomeNoEngieComponent,
    Servi2AccountsComponent,
    PhonePipe,
    RangePipe,
    CellphoneRegisterComponent,
    RegisterComponent,
    ValidateEmailComponent,
    EngieDetailsComponent,
    LayOnComponent,
    CustomMatPanelComponent,
    HeaderMenuComponent,
    HeaderAppComponent,
    HeaderMenuMobileComponent,
    LoginFormComponent,
    BannerHomeDetailsComponent,
    LoginFormMobileComponent,
    ForgotPasswordFormComponent,
    BannerForgotDetailsComponent,
    ChangePasswordFormComponent,
    BannerCookiesComponent
  ],
  imports: [
    NgProgressModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ScrollingModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    FlexLayoutModule,
    MatTooltipModule,
    MaterialModule,
    ButtonModule,
    OverlayPanelModule,
    SidebarModule,
    CheckboxModule,
    CardModule,
    StoreModule.forRoot({ home: homeReducer, app: appReducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    TooltipModule,
    ProgressSpinnerModule,
    ToastModule
  ],
  providers: [
    MessageService,
    AuthGuard,
    SnackbarService,
    ProgressBarService,
    WebsocketService,
    HttpUrlEncodingCodec,
    { provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,

      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
