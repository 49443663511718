import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from "../../../core/api";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MatDialog } from '@angular/material';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as actionTypes from 'src/app/actions/app.actions';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  div: any;
  notifications: any;
  notificationsSubscr: Subscription;
  userId: string;
  width = '100px';
  userName: string = "";
  expirationTokenDate: number = Number(localStorage.getItem('expirationTokenDate'));
  isLog: boolean = false;

  constructor(private router: Router,
    private clientService: ClientService,
    private idle: Idle,
    public dialog: MatDialog,
    private store: Store<AppState>) {

    if(localStorage.getItem('nameuser')){
      this.userName= `${localStorage.getItem('nameuser')} ${localStorage.getItem('apellidouser')}`;
      let a =  this.userName.split(" ")
      this.userName = `${a[0]} ${localStorage.getItem('apellidouser').slice(0,1).toLocaleUpperCase()}. `;
    }
      /* TODO: implement this in a way that the exipration time always throws a positive number
     * SUGGESTION: Change the logic at the backend of the expiration time
    */
    /**SET Timer de sesion */
    if (this.clientService.isAuthenticated()) {
      this.idle.setIdle(0.1); // set tiempo de ocio
      let expirationTime = this.expirationTokenDate ? moment(this.expirationTokenDate).diff(new Date(), "seconds") : 900;
      this.idle.setTimeout(expirationTime < 900 && expirationTime > 0 ? expirationTime : 900); // set tiempo de timeout
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // set eventos de interrupcion

      /**Evento que se gatilla al momento de que el tiempo de inactividad finalizo */
      this.idle.onTimeout.subscribe(() => {
        this.clientService.logoutClient(true);
        this.openDialog();
      });

      /**Inicio del vigilante del tiempo de inactividad */
      this.idle.watch();

    }
  }

  ngOnInit() {
    this.getUnreadNotifications();
    this.getUserId();
    this.isLogin();
  }

  ngOnDestroy() {
    if (this.notificationsSubscr && !this.notificationsSubscr.closed)
      this.notificationsSubscr.unsubscribe();
  }

  logout() {
    /**Detiene los parametros de la libreria de tiemout*/
    this.idle.stop();
    this.idle.clearInterrupts();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.ngOnDestroy();
    this.clientService.logoutClient();
    this.router.navigate(['/home']);
    this.store.dispatch(new actionTypes.RemoveUserLoggedActions());
    /** */

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '250px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      this.logout();
    });
  }

  getUserId(): void {
    this.userId = this.clientService.getClientId();
  }

  getUnreadNotifications(): void {
    this.notificationsSubscr =
      this.clientService.getUnreadNotifications().subscribe(data => {
        this.notifications = data;
      });
  }

  isLogin() {
    if (localStorage.getItem('nameuser') != null) {
      this.isLog = true;
    }
  }

  goHome():void {
    this.router.navigate(['/dashboard/' + this.userId + '/resumen']);

  }
}
