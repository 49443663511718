import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, } from "@angular/forms";
import { Router, } from "@angular/router";
import { API_MAIN_URL } from "../shared/constants";
import { ClientService } from "../../core/api";
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  url: string = API_MAIN_URL;

  ingresar: FormGroup;

  id: number = 10;

  hide = true;

  checked = false;
  loaded=false;
  tabIndex: number;
  error: string;

  constructor(private router: Router,
    private clientService: ClientService,
    public dialog: MatDialog
  ) {


    this.ingresar = new FormGroup({
      'user': new FormControl('', [
        Validators.required,
        Validators.pattern('^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$')]),
      'password': new FormControl('', Validators.required)
    })
  }

  ngOnInit() {
    this.setTab();
  }

  async login() {
    if ((this.ingresar.value.user).length > 0) {
      this.loaded=true;
      try {
        this.error = null;
        const result = await this.clientService.loginClient(this.ingresar.value.user, this.ingresar.value.password, 'CLIENT').toPromise();
        this.clientService.redirectLogin(result);
      } catch({ error }) {
        this.loaded=false;
        let errorString: string = error.error;
        errorString = errorString.charAt(0).toUpperCase() + errorString.slice(1).toLowerCase();
        this.error = errorString;
      }
    }
  }

  email = new FormControl('', [Validators.required, Validators.email]);
  emailConfir = new FormControl('', [Validators.required, Validators.email]);

  passwordValidator(g: FormControl) {
    return g.get('password').value === g.get('repitPassword').value
      ? null : { 'mismatch': true };
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Por favor ingrese un correo electronico' :
      this.email.hasError('email') ? 'Correo no valido' : '';
  }

  registerRSS() {

  }

  /**
   * Function to set weter is register
   * or login view
   */
  setTab() {
    this.tabIndex = this.clientService.getIsRegister() ? 1 : 0;
    this.clientService.setIsRegister(false);
  }
}



