import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { PASSWORD_PATTERN } from 'src/app/contants/app.contants';
import { ClientService } from 'src/app/core/api';
import { AppState } from 'src/app/interfaces/app.interface';
import * as actionTypes from 'src/app/actions/app.actions';
import * as MESSAGES from 'src/app/contants/messages.contants';

@Component({
  selector: 'change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  validateEmailToken: string;
  error: string;
  openLoginForm: boolean;

  messageSource: any = MESSAGES;

  hide: boolean = true;
  hideConfirm: boolean = true;

  constructor(private formBuilder: FormBuilder,
              private clientService: ClientService,
              private route: ActivatedRoute,
              private router: Router,
              private decoded: HttpUrlEncodingCodec,
              private messageService: MessageService,
              private activate: ActivatedRoute,
              private store: Store<AppState>
  ) {
    this.store.select('home').subscribe(
      home => this.openLoginForm = home.openLoginForm
    );

    this.activate.queryParams.subscribe(params => {
      if (params.message != undefined && params.message != null && params.message != "") {
        this.messageService.add({severity: 'success', summary:'Exito', detail:params.message});
      }
    });

    this.changePasswordFormGroup = this.formBuilder.group({
      password: new FormControl('', [Validators.required,
        Validators.pattern(PASSWORD_PATTERN)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required]),
    }, {validator: this.globalFormValidator});

  };

  ngOnInit() {
    this.getQueryParams();
  }

  globalFormValidator(form: FormGroup): { [key: string]: boolean } | null {
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');
    let errorObj = {};
    if (password.dirty && confirmPassword.dirty && password.value != confirmPassword.value && confirmPassword.value != '') {
      errorObj['passwordsNotEqual'] = true;
    }
    return !!Object.keys(errorObj).length ? errorObj : null;
  }

  getQueryParams() {
    const queryParamsSubs = this.route.queryParamMap.subscribe(params => {
      if (params.get('token'))
        this.validateEmailToken = this.decoded.decodeValue(params.get('token').toString().replace(/\s/g, '+'));
      if (!this.validateEmailToken)
        this.router.navigate(['/home']);
      else
        this.router.navigate([], {queryParams: {token: null}, queryParamsHandling: 'merge'});
    });
  }

  backTohome(){
    this.router.navigate(['home']);
  }



  async sendPassword() {
    try {
      this.store.dispatch(new actionTypes.OpenLoadingActions());
      const response = await this.clientService.updatePassword(this.validateEmailToken, this.changePasswordFormGroup.value.password).toPromise();

      if (response) {
        this.messageService.add({severity: 'success', summary:'Exito', detail:'¡Hemos restablecido tu nueva contraseña con éxito!'});
        this.backTohome();
      }
    } catch (err) {
      this.messageService.add({severity: 'error', summary:'Error', detail: err.error.error});
    }
    finally {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    }

  }

}
