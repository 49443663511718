
import { Action } from '@ngrx/store';

export const OPEN_LOGIN_VIEW = '[Home] Open login view';
export const CLOSE_LOGIN_VIEW = '[Home] Close login view';
export const SET_ACCEPTED_COOKIES = '[Home] Accepted cookies';


export class OpenLoginviewActions implements Action {
  readonly type = OPEN_LOGIN_VIEW;
}

export class CloseLoginViewActions implements Action {
  readonly type = CLOSE_LOGIN_VIEW;
}

export class AcceptedCookiesActions implements Action {
  readonly type = SET_ACCEPTED_COOKIES;
}

export type actions = OpenLoginviewActions | CloseLoginViewActions | AcceptedCookiesActions;

