import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent implements OnInit {
 	
  private file=null;

  constructor(
    public dialogRef: MatDialogRef<DocumentDialogComponent>,
    private domSanitizer: DomSanitizer
    ) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

	fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      this.file = fileList[0]
    }
	}

  deleteDocument(){
  	this.file=null;
  }

  onSave() {
    this.dialogRef.close(this.file);
  }
}
