import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/core/api';
import {API_MAIN_URL, MESSAGE_TIMES} from '../constants';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { SnackbarService } from 'src/app/core/api/snackbar.service';

@Component({
  selector: 'app-lay-on',
  templateUrl: './lay-on.component.html',
  styleUrls: ['./lay-on.component.scss']
})
export class LayOnComponent implements OnInit {

  ingresar: FormGroup;
  validateEmailToken: string;
  hide = true;
  error: string;
  text:boolean;
  constructor(private router: Router,
    private clientService: ClientService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBarService: SnackbarService,
    private decoded: HttpUrlEncodingCodec
  ) {


    this.ingresar = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern('^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$')]),
      'password': new FormControl('', Validators.required)
    })
  }

  redirect(){
    this.router.navigate(['/login']);
  }
      sendEmail(){
      
        let token = this.validateEmailToken;
      this.clientService.lay_On_Account(token).subscribe(
        
        response=>
        {
          this.snackBarService.openSnackBar(MESSAGE_TIMES.SUCCESS_MESSAGE, 'Activacion de cuenta exitoso', 'success');
          this.text=true;

        },
        err=>{
          console.log("Con errores",err.error.error);
          this.error = err.error.error
          this.text =false
          
      })
      
    
      }

  ngOnInit() {
   this.getQueryParams();
   this.sendEmail();
  }
  getQueryParams() {
    const queryParamsSubs = this.route.queryParamMap.subscribe(params => {
      if (params.get('token'))
        this.validateEmailToken = this.decoded.decodeValue(params.get('token').toString().replace(/\s/g, '+'));
      if (!this.validateEmailToken)
        this.router.navigate(['/home']);
      else
        this.router.navigate([], {queryParams: {token: null}, queryParamsHandling: 'merge'});
    });
    console.log(this.validateEmailToken);
  }
  backTohome(){
    this.router.navigate(['login']);
    this.hide = true;
  }
  


  /**
   * Function to set weter is register
   * or login view
   */
 

}
