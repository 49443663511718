import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { API_MAIN_URL } from "../shared/constants";
import { ClientService } from "../../core/api";
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as actionTypes from 'src/app/actions/home.actions';
import * as appActionTypes from 'src/app/actions/app.actions';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  url: string = API_MAIN_URL;

  ingresar: FormGroup;

  id: number = 10;

  hide = true;

  checked = false;
  tabIndex: number;
  error: string;

  constructor(private router: Router,
    private clientService: ClientService,
    private store: Store<AppState>
  ) {

    this.ingresar = new FormGroup({
      'user': new FormControl('', [
        Validators.required,
        Validators.email]),
      'password': new FormControl('', Validators.required)
    })
  }

  async login() {
    if ((this.ingresar.value.user).length > 0) {
      this.store.dispatch(new appActionTypes.OpenLoadingActions());
      try {
        this.error = null;
        const result = await this.clientService.loginClient(this.ingresar.value.user, this.ingresar.value.password, 'CLIENT').toPromise();
        this.clientService.redirectLogin(result);
        this.store.dispatch(new appActionTypes.SetUserLoggedActions());
        this.store.dispatch(new actionTypes.CloseLoginViewActions());
      } catch({ error }) {
        let errorString: string = error.error;
        errorString = errorString.charAt(0).toUpperCase() + errorString.slice(1).toLowerCase();
        this.error = errorString;
      }
      finally {
        this.store.dispatch(new appActionTypes.CloseLoadingActions());
      }
    }
  }

  goForgotPassword(): void {
    this.router.navigate(['forgetpass']);
    this.store.dispatch(new actionTypes.CloseLoginViewActions());
  }

}



