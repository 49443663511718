import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-reminder-dialog',
  templateUrl: './reminder-dialog.component.html',
  styleUrls: ['./reminder-dialog.component.scss']
})
export class ReminderDialogComponent implements OnInit {

  title: string;
  body: string;
  buttonText: string
    
    constructor(
      public dialogRef: MatDialogRef<ReminderDialogComponent>
    ) { 
      
    }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
